

































































































































































































































































































import Vue from "vue";
import { settingsServices } from "@/services/settings.service";
import { fileServices } from "@/services/file.service";
import {
  ResponseListOfCompany,
} from "@/models/interface/settings.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import {
  checkTaxNumber,
  formatTaxNumber,
  formatTaxNumbertoNumber,
  phoneValidatorv2,
  emailValidator,
  letterKeydown,
} from "@/validator/globalvalidator";
import { ResponseUploadData } from "@/models/interface/assets.interface";
import { MAXIMUM_FILE_SIZE } from "@/models/constant/global.constant";
import { mapGetters } from "vuex";
import { ALLOWED_IMAGE_FILE } from "@constant/upload.constant";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  name: "Company",
  data() {
    return {
      ALLOWED_IMAGE_FILE,
      formatTax: formatTaxNumber,
      formatTaxtoNumber: formatTaxNumbertoNumber,
      direction: "" as string,
      isFormSubmitted: false as boolean,
      dataListCompany: {} as ResponseListOfCompany,
      logoValue: "" as string,
      form: this.$form.createForm(this, { name: "company" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      trigerdisable: false as boolean,
      search: "" as string,
      titlemodalOpen: "" as string,
      modalOpen: false as boolean,
      sort: "" as string,
      pageSizeSet: 10,
      typemodal: "" as string,
      loadingTable: false as boolean,
      columnsTable: [
        {
          title: this.$t("lbl_name"),
          dataIndex: "name",
          key: "name",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_address"),
          dataIndex: "address",
          key: "address",
          sorter: true,
          scopedSlots: { customRender: "isNull" },
          width: 100,
        },
        {
          title: this.$t("lbl_tax_number"),
          dataIndex: "taxNumber",
          key: "taxNumber",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_phone"),
          dataIndex: "phone",
          key: "phone",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_fax"),
          dataIndex: "fax",
          key: "fax",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_website"),
          dataIndex: "website",
          key: "website",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_email"),
          dataIndex: "email",
          key: "email",
          sorter: true,
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
      formRules: {
        name: {
          label: this.$t("lbl_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        address: {
          label: this.$t("lbl_address"),
          name: "address",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "address",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        code: {
          label: this.$t("lbl_code"),
          name: "code",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        tax: {
          label: this.$t("lbl_tax_number"),
          name: "tax",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "tax",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
                {
                  validator: checkTaxNumber,
                },
              ],
            },
          ],
        },
        phone: {
          label: this.$t("lbl_phone"),
          name: "phone",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "phone",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
                {
                  validator: phoneValidatorv2,
                },
              ],
            },
          ],
        },
        fax: {
          label: this.$t("lbl_fax"),
          name: "fax",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "fax",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        email: {
          label: this.$t("lbl_email"),
          name: "email",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "email",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
                {
                  validator: emailValidator,
                },
              ],
            },
          ],
        },
        website: {
          label: this.$t("lbl_website"),
          name: "website",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "website",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        logo: {
          label: this.$t("lbl_logo"),
          name: "logo",
          decorator: [
            "logo",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      fileList: [] as any,
      dataDetail: "",
      authPrivilege: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      userPrivileges: "authStore/GET_USER_PRIVILEGES"
    }),
    formLayout(): { labelCol: { span: number }; wrapperCol: { span: number } } {
      return { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
    },
    ableToCreate(): boolean {
      return this.userPrivileges.find(x => x.key === "company" && x.privilege.create && x.privilege.update);
    },
  },
  created() {
    this.getListOfCompany("");
    this.setAuthorities();
  },
  methods: {
    letterKeydown,
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "company") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    onBlur(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatTax(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as any;
        dataobj[form] = this.formatTaxtoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    deleteLogo(): void {
      this.logoValue = "";
    },
    handleRemoveFile(file): void {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file): boolean {
      const isLt5M = file.size;
      if (isLt5M >= MAXIMUM_FILE_SIZE) {
        this.$message.error(this.$t("lbl_upload_info_1").toString());
        return isLt5M;
      }
      this.fileList = [...this.fileList, file];
      return false;
    },
    async uploadFile(file): Promise<ResponseUploadData> {
      const formData = new FormData();
      formData.append("file", file);
      return fileServices.uploadFile(formData);
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.resetFilter(false);
      }
      this.getListOfCompany("");
    },
    reponseEditTable(response): void {
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_company").toString();
      this.resetFilter(false);
      this.dataDetail = "/" + response.data.id;
      this.modalOpen = true;
      const idtimeout = setTimeout(() => {
        this.form.setFieldsValue({
          name: response.data.name,
          address: response.data.address,
          tax: this.formatTax(this.formatTaxtoNumber(response.data.taxNumber)),
          phone: response.data.phone,
          fax: response.data.fax,
          email: response.data.email,
          website: response.data.website,
        });
        this.logoValue = response.data.logo;
        clearTimeout(idtimeout);
      }, 200);
    },
    reponseDeleteTable(response): void {
      const path = "/" + response.data.id;
      settingsServices.deleteCompany(path).then(() => {
        this.resetFilter(false);
        this.getListOfCompany("");
      });
    },
    resetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfCompany("");
    },
    reponseSearchInput(response): void {
      if (response) this.search = "name~*" + response + "*";
      else this.search = "";
      this.getListOfCompany("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfCompany("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfCompany("");
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
      this.logoValue = "";
      this.fileList = [];
      this.form.resetFields();
      this.titlemodalOpen = this.$t("lbl_create_new_company").toString();
      this.resetFilter(false);
    },
    getListOfCompany(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "createdDate:desc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      settingsServices
        .listOfCompany(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.map(
              (dataObject, index) => (
                (dataObject.key = index),
                (dataObject.taxNumber = this.formatTax(dataObject.taxNumber))
              )
            );
            this.dataListCompany = res;
          } else {
            const idtimeout = setTimeout(() => {
              this.form.setFieldsValue({
                name: res.name,
                address: res.address,
                tax: this.formatTax(res.taxNumber),
                phone: res.phone,
                fax: res.fax,
                email: res.email,
                website: res.website,
              });
            }, 200);
            this.modalOpen = true;
            clearTimeout(idtimeout);
          }
        })
        .finally(() => (this.loadingTable = false));
    },
    async createNewCompany(datapost): Promise<void> {
      try {
        if (this.fileList.length) {
          const { url } = await this.uploadFile(this.fileList[0]);
          datapost.logo = url;
        }
        await settingsServices.createCompany(datapost);
        this.modalOpen = false;
        this.getListOfCompany("");
        this.form.resetFields();
        this.$message.success(this.$t("notif_create_success").toString());
      } catch (error) {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("notif_create_fail").toString(),
        });
      } finally {
        this.isFormSubmitted = false;
      }
    },
    async updateCompany(datapost): Promise<void> {
      try {
        if (this.fileList.length) {
          const { url } = await this.uploadFile(this.fileList[0]);
          datapost.logo = url;
        }
        await settingsServices.updateCompany(datapost, this.dataDetail);
        this.modalOpen = false;
        this.getListOfCompany("");
        this.form.resetFields();
        this.$message.success(this.$t("notif_update_success").toString());
      } catch (error) {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("notif_update_fail").toString(),
        });
      } finally {
        this.isFormSubmitted = false;
      }
    },
    submitForm(e: Event, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const datapost = {
            name: values.name,
            address: values.address,
            taxNumber: this.formatTaxtoNumber(values.tax),
            phone: values.phone,
            fax: values.fax,
            website: values.website,
            email: values.email,
            logo: this.logoValue,
          };
          this.isFormSubmitted = true;
          if (type === "create") {
            this.createNewCompany(datapost);
          } else {
            this.updateCompany(datapost);
          }
        } else {
          this.$notification.error({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
  },
});
