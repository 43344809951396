var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-card",
            { attrs: { title: _vm.$t("lbl_company") } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 10 } },
                    [
                      _c("InputSearch", {
                        on: { "input-search": _vm.reponseSearchInput }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 6 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.resetFilter(true)
                            }
                          }
                        },
                        [
                          _c("a-icon", {
                            staticClass: "icon-reload",
                            attrs: { type: "reload" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 8, align: "end" } },
                    [
                      _vm.ableToCreate
                        ? _c(
                            "a-button",
                            {
                              attrs: { icon: "plus", type: "primary" },
                              on: { click: _vm.createNew }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("TableCustom", {
                        attrs: {
                          idtable: "table1",
                          "on-select-change": null,
                          "selected-row-keys": null,
                          "data-source": _vm.dataListCompany.data,
                          columns: _vm.columnsTable,
                          scroll: { x: 2000 },
                          paginationcustom: true,
                          "default-pagination": false
                        },
                        on: {
                          "on-delete": _vm.reponseDeleteTable,
                          "on-edit": _vm.reponseEditTable,
                          "on-tablechange": _vm.onSorterChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 18 } },
                    [
                      _c("Pagination", {
                        attrs: {
                          total: _vm.dataListCompany.totalElements,
                          "page-size-set": _vm.limit,
                          "id-pagination": "pagination1"
                        },
                        on: {
                          "response-pagesize-change":
                            _vm.responsePageSizeChange,
                          "response-currentpage-change":
                            _vm.responseCurrentPageChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { sm: 24, md: 6, align: "end" } },
                    [
                      _c("a-tag", { attrs: { color: "grey" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lbl_total_found")) +
                            " : " +
                            _vm._s(_vm.dataListCompany.totalElements) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            "mask-closable": false,
            width: 700,
            title: _vm.titlemodalOpen,
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "template",
            { slot: "contentmodal" },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.form,
                    "label-col": _vm.formLayout.labelCol,
                    "wrapper-col": _vm.formLayout.wrapperCol,
                    "label-align": "left",
                    layout: "horizontal"
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.formRules.name.label } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.name.decorator,
                                    expression: "formRules.name.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.name.name,
                                  placeholder: _vm.formRules.name.placeholder,
                                  autocomplete: "off",
                                  disabled: _vm.trigerdisable
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.formRules.address.label } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.address.decorator,
                                    expression: "formRules.address.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.address.name,
                                  placeholder:
                                    _vm.formRules.address.placeholder,
                                  autocomplete: "off",
                                  disabled: _vm.trigerdisable
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              staticStyle: { "margin-bottom": "unset" },
                              attrs: { label: _vm.formRules.tax.label }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.tax.decorator,
                                    expression: "formRules.tax.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.tax.name,
                                  placeholder: _vm.formRules.tax.placeholder,
                                  autocomplete: "off"
                                },
                                on: {
                                  blur: function($event) {
                                    return _vm.onBlur($event, "tax")
                                  },
                                  focus: function($event) {
                                    return _vm.onFocus($event, "tax")
                                  }
                                }
                              }),
                              _c(
                                "small",
                                { attrs: { slot: "help" }, slot: "help" },
                                [
                                  _c("ul", [
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_tax_number_rule_1"))
                                      )
                                    ]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_tax_number_rule_2"))
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.formRules.phone.label } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.phone.decorator,
                                    expression: "formRules.phone.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.phone.name,
                                  placeholder: _vm.formRules.phone.placeholder,
                                  autocomplete: "off",
                                  disabled: _vm.trigerdisable,
                                  type: "tel"
                                },
                                on: { keydown: _vm.letterKeydown }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.formRules.email.label } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.email.decorator,
                                    expression: "formRules.email.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.email.name,
                                  placeholder: _vm.formRules.email.placeholder,
                                  autocomplete: "off",
                                  disabled: _vm.trigerdisable
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.formRules.website.label } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.website.decorator,
                                    expression: "formRules.website.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.website.name,
                                  placeholder:
                                    _vm.formRules.website.placeholder,
                                  autocomplete: "off",
                                  disabled: _vm.trigerdisable
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          !_vm.logoValue
                            ? _c(
                                "a-form-item",
                                { attrs: { label: _vm.formRules.logo.label } },
                                [
                                  _c(
                                    "a-upload",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value: _vm.formRules.logo.decorator,
                                          expression: "formRules.logo.decorator"
                                        }
                                      ],
                                      attrs: {
                                        "file-list": _vm.fileList,
                                        remove: _vm.handleRemoveFile,
                                        "before-upload": _vm.beforeUpload,
                                        name: _vm.formRules.logo.name,
                                        accept: _vm.ALLOWED_IMAGE_FILE.join(
                                          ", "
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        { attrs: { icon: "upload" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("lbl_upload")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "small",
                                    { attrs: { slot: "help" }, slot: "help" },
                                    [
                                      _c("ul", [
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(_vm.$t("lbl_upload_info_1"))
                                          )
                                        ]),
                                        _c("li", [
                                          _vm._v(
                                            _vm._s(_vm.$t("lbl_upload_info_2"))
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "a-form-item",
                                { attrs: { label: _vm.formRules.logo.label } },
                                [
                                  _c("img", {
                                    attrs: {
                                      loading: "lazy",
                                      src: _vm.logoValue,
                                      alt: "logo",
                                      width: "100%",
                                      height: "auto"
                                    }
                                  }),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: { click: _vm.deleteLogo }
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "delete" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 12 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: _vm.formRules.fax.label } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.fax.decorator,
                                    expression: "formRules.fax.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.fax.name,
                                  placeholder: _vm.formRules.fax.placeholder,
                                  autocomplete: "off",
                                  disabled: _vm.trigerdisable
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footermodal" },
            [
              _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
              ]),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: {
                    type: "primary",
                    loading: _vm.isFormSubmitted,
                    "html-type": "submit"
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitForm($event, _vm.typemodal)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }